import Northsquare1 from '../../../Media/Ruko Kotwis/Northsquare/NorthSquare (1).webp'
import Northsquare2 from '../../../Media/Ruko Kotwis/Northsquare/NorthSquare (2).webp'
import Northsquare3 from '../../../Media/Ruko Kotwis/Northsquare/NorthSquare (3).webp'
import Northsquare4 from '../../../Media/Ruko Kotwis/Northsquare/NorthSquare (4).webp'
import Northsquare5 from '../../../Media/Ruko Kotwis/Northsquare/NorthSquare (5).webp'
import Northsquare6 from '../../../Media/Ruko Kotwis/Northsquare/NorthSquare (6).webp'
import Northsquare7 from '../../../Media/Ruko Kotwis/Northsquare/NorthSquare (7).webp'
import Northsquare8 from '../../../Media/Ruko Kotwis/Northsquare/NorthSquare (8).webp'
import Northsquare9 from '../../../Media/Ruko Kotwis/Northsquare/NorthSquare (9).webp'
import Northsquare10 from '../../../Media/Ruko Kotwis/Northsquare/NorthSquare (10).webp'

const Northsquareimages =[
Northsquare1,Northsquare2,Northsquare3,Northsquare4,Northsquare5,Northsquare6,Northsquare7,Northsquare8,Northsquare9,Northsquare10
]

export default Northsquareimages;