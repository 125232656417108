import Devant1 from '../../../Media/Ruko Kotwis/Devant/Devant (1).webp'
import Devant2 from '../../../Media/Ruko Kotwis/Devant/Devant (2).webp'
import Devant3 from '../../../Media/Ruko Kotwis/Devant/Devant (3).webp'
import Devant4 from '../../../Media/Ruko Kotwis/Devant/Devant (4).webp'
import Devant5 from '../../../Media/Ruko Kotwis/Devant/Devant (5).webp'
import Devant6 from '../../../Media/Ruko Kotwis/Devant/Devant (6).webp'
import Devant7 from '../../../Media/Ruko Kotwis/Devant/Devant (7).webp'
import Devant8 from '../../../Media/Ruko Kotwis/Devant/Devant (8).webp'
import Devant9 from '../../../Media/Ruko Kotwis/Devant/Devant (9).webp'
import Devant10 from '../../../Media/Ruko Kotwis/Devant/Devant (10).webp'
import Devant11 from '../../../Media/Ruko Kotwis/Devant/Devant (11).webp'

const Devantimages =[
Devant1,Devant2,Devant3,Devant4,Devant5,Devant6,Devant7,Devant8,Devant9,Devant10,Devant11
]

export default Devantimages;