import React from "react";
import "./about.scss";
import logo from "../../Media/Logo_Kotwis (2).webp";

const aboutparkspring = () => {
  return (
    <div id="about" className="container-aboutparkspring">
      <div className="logo-aboutparkspring">
        <img
          className="gambar-logoparkspring"
          src={logo}
          alt="logo-parkspring"
        />
      </div>
      <div className="container-deskripsiparkspring">
        <h1>Embracing Nature Better Future</h1>
        <div className="deskripsi-aboutparkspring">
          Kota Wisata Cibubur adalah kawasan perumahan di timur Jakarta,
          tepatnya di Kecamatan Gunung Putri, Kabupaten Bogor, Jawa Barat.
          Kawasan Kota Wisata Cibubur seluas 700 hektar ini dikembangkan oleh
          Sinarmas Land sejak tahun 1997 guna untuk menjadi kota mandiri modern.
          Kota Wisata Cibubur mengintegrasikan berbagai aspek kehidupan,
          termasuk tempat tinggal, fasilitas pendidikan, rekreasi, dan area
          komersial dalam satu lingkungan yang harmonis. Kota Wisata Cibubur
          mengusung konsep "living in harmony" yang menekankan keseimbangan
          antara kehidupan sehari-hari, pendidikan, rekreasi, dan olahraga.
          Setiap kluster perumahan dirancang dengan desain yang terinspirasi
          dari kota-kota besar di dunia.
        </div>
      </div>
    </div>
  );
};

export default aboutparkspring;
