import Vermont1 from '../../../Media/Kotwis/Vermont/Vermont (1).webp'
import Vermont2 from '../../../Media/Kotwis/Vermont/Vermont (2).webp'
import Vermont3 from '../../../Media/Kotwis/Vermont/Vermont (3).webp'
import Vermont4 from '../../../Media/Kotwis/Vermont/Vermont (4).webp'
import Vermont5 from '../../../Media/Kotwis/Vermont/Vermont (5).webp'
import Vermont6 from '../../../Media/Kotwis/Vermont/Vermont (6).webp'
import Vermont7 from '../../../Media/Kotwis/Vermont/Vermont (7).webp'



const Vermontimages =[
Vermont1,Vermont2,Vermont3,Vermont4,Vermont5,Vermont6,Vermont7
]

export default Vermontimages;