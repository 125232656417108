import Davinci1 from '../../../Media/Kotwis/Davinci/Davinci (1).webp'
import Davinci2 from '../../../Media/Kotwis/Davinci/Davinci (2).webp'
import Davinci3 from '../../../Media/Kotwis/Davinci/Davinci (3).webp'
import Davinci4 from '../../../Media/Kotwis/Davinci/Davinci (4).webp'



const Davinciimages =[ 
    Davinci1,Davinci2,Davinci3,Davinci4
]

export default Davinciimages;