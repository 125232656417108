import Missisipi1 from '../../../Media/Kotwis/Missisipi/Missisipi (1).webp'
import Missisipi2 from '../../../Media/Kotwis/Missisipi/Missisipi (2).webp'
import Missisipi3 from '../../../Media/Kotwis/Missisipi/Missisipi (3).webp'
import Missisipi4 from '../../../Media/Kotwis/Missisipi/Missisipi (4).webp'
import Missisipi5 from '../../../Media/Kotwis/Missisipi/Missisipi (5).webp'
import Missisipi6 from '../../../Media/Kotwis/Missisipi/Missisipi (6).webp'
import Missisipi7 from '../../../Media/Kotwis/Missisipi/Missisipi (7).webp'
import Missisipi8 from '../../../Media/Kotwis/Missisipi/Missisipi (8).webp'
import Missisipi9 from '../../../Media/Kotwis/Missisipi/Missisipi (9).webp'
import Missisipi10 from '../../../Media/Kotwis/Missisipi/Missisipi (10).webp'

const Missisipiimages =[
Missisipi1,Missisipi2,Missisipi3,Missisipi4,Missisipi5,Missisipi6,Missisipi7,Missisipi8,Missisipi9,Missisipi10
]

export default Missisipiimages;