import React from "react";
import "./ruko1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Devantimages from "./Devant";
import Shophouseimages from "./Shophouse";
import Northsquareimages from "./Northsquare";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLayerGroup,
  faLocationDot,
  faMedal,
  faToilet,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const ruko1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Northsquarewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281818160028&text=Halo%20Christine,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Wisata%20Cibubur%20(Ruko%20North%20Square%20District)%20https://marketing-kotawisata.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Shophousewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281818160028&text=Halo%20Christine,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Wisata%20Cibubur%20(Ruko%20Shopehouses)%20https://marketing-kotawisata.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Devantwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281818160028&text=Halo%20Christine,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Wisata%20Cibubur%20(Ruko%20Devant%20Business%20Loft)%20https://marketing-kotawisata.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard3">
        <div className="card">
          <Slider {...settings}>
            {Northsquareimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">North Square District</div>
            <div className="gridmap">
              <div className="namalokasi">
                Tanpa DP, Discount s/d Ratusan Juta
              </div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 106m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 255m²</span>
            <FontAwesomeIcon color="#091c34" icon={faLayerGroup} />
            <span> 3</span>
            <FontAwesomeIcon color="#091c34" icon={faToilet} />
            <span> 3</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">10 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Northsquarewa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Shophouseimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Shophouses</div>
            <div className="gridmap">
              <div className="namalokasi">
                Tanpa DP, Discount s/d Ratusan Juta
              </div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 123m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 159m²</span>
            <FontAwesomeIcon color="#091c34" icon={faLayerGroup} />
            <span> 3</span>
            <FontAwesomeIcon color="#091c34" icon={faToilet} />
            <span> 3</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">12 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Shophousewa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Devantimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Devant Business Loft</div>
            <div className="gridmap">
              <div className="namalokasi">
                Tanpa DP, Discount s/d Ratusan Juta
              </div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 117m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 119m²</span>
            <FontAwesomeIcon color="#091c34" icon={faLayerGroup} />
            <span> 4</span>
            <FontAwesomeIcon color="#091c34" icon={faToilet} />
            <span> 4</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">37 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Devantwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default ruko1;
