import Atherton1 from '../../../Media/Kotwis/Atherton/Atherton (1).webp'
import Atherton2 from '../../../Media/Kotwis/Atherton/Atherton (2).webp'
import Atherton3 from '../../../Media/Kotwis/Atherton/Atherton (3).webp'
import Atherton4 from '../../../Media/Kotwis/Atherton/Atherton (4).webp'
import Atherton5 from '../../../Media/Kotwis/Atherton/Atherton (5).webp'
import Atherton6 from '../../../Media/Kotwis/Atherton/Atherton (6).webp'
import Atherton7 from '../../../Media/Kotwis/Atherton/Atherton (7).webp'
import Atherton8 from '../../../Media/Kotwis/Atherton/Atherton (8).webp'
import Atherton9 from '../../../Media/Kotwis/Atherton/Atherton (9).webp'
import Atherton10 from '../../../Media/Kotwis/Atherton/Atherton (10).webp'
import Atherton11 from '../../../Media/Kotwis/Atherton/Atherton (11).webp'
import Atherton12 from '../../../Media/Kotwis/Atherton/Atherton (12).webp'
import Atherton13 from '../../../Media/Kotwis/Atherton/Atherton (13).webp'
import Atherton14 from '../../../Media/Kotwis/Atherton/Atherton (14).webp'

const Athertonimages =[
Atherton1,Atherton2,Atherton3,Atherton4,Atherton5,Atherton6,Atherton7,Atherton8,Atherton9,Atherton10,Atherton11,Atherton12,Atherton13,Atherton14
]

export default Athertonimages;