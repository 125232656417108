import Richmond1 from '../../../Media/Kotwis/Richmond/Richmond (1).webp'
import Richmond2 from '../../../Media/Kotwis/Richmond/Richmond (2).webp'
import Richmond3 from '../../../Media/Kotwis/Richmond/Richmond (3).webp'
import Richmond4 from '../../../Media/Kotwis/Richmond/Richmond (4).webp'
import Richmond5 from '../../../Media/Kotwis/Richmond/Richmond (5).webp'
import Richmond6 from '../../../Media/Kotwis/Richmond/Richmond (6).webp'
import Richmond7 from '../../../Media/Kotwis/Richmond/Richmond (7).webp'
import Richmond8 from '../../../Media/Kotwis/Richmond/Richmond (8).webp'
import Richmond9 from '../../../Media/Kotwis/Richmond/Richmond (9).webp'
import Richmond10 from '../../../Media/Kotwis/Richmond/Richmond (10).webp'
import Richmond11 from '../../../Media/Kotwis/Richmond/Richmond (11).webp'
import Richmond12 from '../../../Media/Kotwis/Richmond/Richmond (12).webp'
import Richmond13 from '../../../Media/Kotwis/Richmond/Richmond (13).webp'
import Richmond14 from '../../../Media/Kotwis/Richmond/Richmond (14).webp'
import Richmond15 from '../../../Media/Kotwis/Richmond/Richmond (15).webp'
import Richmond16 from '../../../Media/Kotwis/Richmond/Richmond (16).webp'
import Richmond17 from '../../../Media/Kotwis/Richmond/Richmond (17).webp'
import Richmond18 from '../../../Media/Kotwis/Richmond/Richmond (18).webp'
import Richmond19 from '../../../Media/Kotwis/Richmond/Richmond (19).webp'

const Richmondimages =[
Richmond1,Richmond2,Richmond3,Richmond4,Richmond5,Richmond6,Richmond7,Richmond8,Richmond9,Richmond10,Richmond11,Richmond12,Richmond13,Richmond14,Richmond15,Richmond16,Richmond17,Richmond18,Richmond19
]

export default Richmondimages;